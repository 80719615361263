












































































import { WatchLoading } from "@/decorators/Loading";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";
import SendIcon from "@/assets/icons/saxcons/send-linear.svg";
import {
  SportangoTextField,
  SportangoTextArea
} from "@/components/Inputs/overrides";
import { requiredFieldRule } from "@/utils/validation";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { addDoc, collection } from "@firebase/firestore";
import {
  BaseUser,
  Program,
  USER_EMAILS,
  Broadcast,
  BATCH_SMS_MESSAGES,
  SmsBroadcast
} from "@sportango/backend";
import { DB } from "@/firebase";
import { forFirestore } from "@/utils/parser";
import ProgramsAutoComplete from "@/components/Inputs/ProgramsAutoComplete.vue";
import { convertUserPhoneNumber } from "@/store/actions/users";
import { Watch } from "vue-property-decorator";

@Component({
  name: "send-broadcast",
  components: {
    ProgramsAutoComplete,
    SportangoTextField,
    SendIcon,
    SportangoTextArea
  }
})
export default class SendBroadcast extends mixins(
  CurrentUserMixin,
  LoadingMixin,
  ResponsiveMixin
) {
  isValid = false;
  subject = `Important message`;
  body = "";
  selectedPrograms: Array<string> = [];
  sendToCoaches = true;
  sendToAdmins = true;
  sendSms = false;
  sendToAll = false;
  allUsersFetched = false;

  readonly requiredFieldRule = requiredFieldRule;

  get infoMessage(): string {
    let message = "";
    const receivers = [];
    if (this.sendToCoaches) {
      receivers.push("coaches");
    }
    if (this.sendToAdmins) {
      receivers.push("admins");
    }
    if (receivers.length === 0) {
      message = "This message will be sent to all program players via Email";
    } else if (receivers.length === 1) {
      message = `This message will be sent to all program players and ${receivers[0]} via Email`;
    } else {
      message = `This message will be sent to all program players, ${receivers.join(
        " and "
      )} via Email`;
    }
    if (this.sendSms) {
      message += " and SMS";
    }
    return message;
  }

  get selectedProgramItems(): Array<Program> {
    return this.$store.getters.programs.filter((p) => {
      if (p.id) {
        return this.selectedPrograms.includes(p.id);
      }
      return false;
    });
  }

  get selectedProgramPlayers(): Array<BaseUser> {
    const programPlayers: string[] = [];
    this.selectedProgramItems.forEach((p) => {
      if (p.programPlayers && p.programPlayers.length > 0) {
        p.programPlayers.forEach((p) => {
          if (p.uid) {
            programPlayers.push(p.uid);
          }
        });
      }
    });
    return this.$store.getters.users.filter((u) =>
      programPlayers.includes(u.uid)
    );
  }

  @WatchLoading()
  @Watch("sendToAll")
  async watchSendToAll() {
    if (this.sendToAll && !this.allUsersFetched) {
      this.$store.commit("users", []);
      await this.$store.dispatch("getUsers");
      this.allUsersFetched = true;
    }
  }

  @WatchLoading()
  async send() {
    if (this.isValid) {
      const emails: string[] = [];
      const phoneNumbers: string[] = [];
      if (this.sendToAll) {
        this.$store.getters.users.forEach((u) => {
          if (u.email) {
            emails.push(u.email);
          }
          if (
            u.phoneNumber &&
            !u.additionalInfo?.communicationSettings?.noSMS
          ) {
            phoneNumbers.push(u.phoneNumber);
          }
        });
      } else {
        this.selectedProgramPlayers.forEach((p) => {
          if (p.email) {
            emails.push(p.email);
          }
          if (
            p.phoneNumber &&
            !p.additionalInfo?.communicationSettings?.noSMS
          ) {
            phoneNumbers.push(p.phoneNumber);
          }
        });
        if (this.sendToCoaches || this.sendToAdmins) {
          this.$store.getters.users.forEach((u) => {
            if (u.permissions) {
              if (
                u.permissions.hasCoachAccess &&
                !u.disabled &&
                this.sendToCoaches
              ) {
                if (u.email) {
                  emails.push(u.email);
                }
                if (
                  u.phoneNumber &&
                  !u.additionalInfo?.communicationSettings?.noSMS
                ) {
                  phoneNumbers.push(u.phoneNumber);
                }
              }
              if (
                u.permissions.hasAdminAccess &&
                !u.disabled &&
                this.sendToAdmins
              ) {
                if (u.email) {
                  emails.push(u.email);
                }
                if (
                  u.phoneNumber &&
                  !u.additionalInfo?.communicationSettings?.noSMS
                ) {
                  phoneNumbers.push(u.phoneNumber);
                }
              }
            }
          });
        }
      }
      if (emails.length > 0) {
        const payload: Broadcast = {
          templateId: "Broadcast",
          to: Array.from(new Set(emails)),
          data: {
            subject: this.subject,
            body: this.body
          }
        };
        await addDoc(collection(DB, USER_EMAILS), forFirestore(payload));
      }
      if (this.sendSms && phoneNumbers.length > 0) {
        const payload: SmsBroadcast = {
          to: phoneNumbers.map((p) => convertUserPhoneNumber(p, "db")),
          body: this.body,
          notifyService: "SportangoUserBroadcast"
        };
        await addDoc(collection(DB, BATCH_SMS_MESSAGES), forFirestore(payload));
      }
      this.$router.replace("/broadcast/sent");
    }
  }

  @WatchLoading()
  async mounted() {
    await this.$store.dispatch("getAllCoaches");
    await this.$store.dispatch("getAllAdmins");
  }
}
